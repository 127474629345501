import { UserUpgradeType } from '../../contexts/AppContext';

import { emotionalIntelligenceQuotes, patienceQuotes, speedQuotes, stressResistanceQuotes } from './quotesEn';
import { emotionalIntelligenceQuotesRu, patienceQuotesRu, speedQuotesRu, stressResistanceQuotesRu } from './quotesRu';

export const getQuote = (type: UserUpgradeType, lang: string): string => {
  let quotes: any[] = [];

  if (lang.includes('en')) {
    if (type === 'fuel') quotes = patienceQuotes;
    if (type === 'speed') quotes = speedQuotes;
    if (type === 'turbo') quotes = emotionalIntelligenceQuotes;
    if (type === 'luck') quotes = stressResistanceQuotes;
  }
  if (lang.includes('ru')) {
    if (type === 'fuel') quotes = patienceQuotesRu;
    if (type === 'speed') quotes = speedQuotesRu;
    if (type === 'turbo') quotes = emotionalIntelligenceQuotesRu;
    if (type === 'luck') quotes = stressResistanceQuotesRu;
  }

  if (quotes.length == 0) return '';

  return quotes[Math.floor(Math.random() * quotes.length)][0];
};
