import styled from 'styled-components';

import PageContainer from '../components/Wrappers';
import { postAirdropTickets } from '../lib/api';
import { useApp } from '../contexts/AppContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { compactAmount, doHapticFeedback, shortenWallet } from '../lib/utils';

import Row from '../components/Row';
import { NavLink } from 'react-router-dom';
import { IAirdrop } from '../lib/types';
import { IconActionMinus } from '../components/Icons/IconActionMinus';
import { IconActionPlus } from '../components/Icons/IconActionPlus';
import { StatsContainer, Label, SubLabel, Value } from './Statistics';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: 0;

  a {
    color: #c4b8da;
    text-decoration: underline;
  }

  h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 76px;
    text-align: center;
    width: 960px;
  }

  h2 {
    color: #fff;
    font-size: 52px;
    width: 960px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
    font-family: mr_ApexMk3, sans-serif;

    span {
      font-family: mr_ApexMk3, sans-serif;
      color: #faff00;
    }
  }
`;

const LargeNote = styled.p.attrs({
  'data-tip': 'airdrop-large-note',
})`
  font-family: Roboto, sans-serif;
  font-size: 42px;
  text-align: center;
  color: #faff00;
`;

const Double = styled.div.attrs({
  'data-tip': 'airdrop-double',
})`
  font-size: 46px;
  font-family: var(--font), sans-serif;
  margin-bottom: 10px;
`;

const BuyTicketButton = styled.button.attrs({
  'data-tip': 'airdrop-buy-ticket-button',
})<{ disabled?: boolean }>`
  height: 130px;
  width: 800px;
  margin: 0 auto 0;

  background: ${({ disabled }) => (disabled ? '#000' : '#00000094')};
  color: ${({ disabled }) => (disabled ? '#555' : '#efff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? 'gray' : '#efff00')};

  font-family: var(--font), sans-serif;
  text-transform: uppercase;
  border-radius: 84px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 32px;
  font-weight: 500;
`;

const CntButton = styled.button.attrs({
  'data-tip': 'cnt-button',
})`
  background: none;
  border: none;
`;

const CntButtonsContainer = styled.div.attrs({
  'data-tip': 'cnt-buttons-container',
})`
  margin-top: 20px;
  margin-bottom: 20px;
  h2 {
    width: 200px !important;
    text-decoration: underline;
  }
`;

const YourWallet = () => {
  const { app } = useApp();
  const { wallet } = app.state;
  const { t } = useTranslation();

  return (
    <div className="your-wallet">
      {!wallet ? t('system.wallet.yourWallet') + ' ' : ''}
      <span>{wallet ? shortenWallet(wallet) : `<${t('system.wallet.notConnected')}>`}</span>
    </div>
  );
};

export const AirdropTickets = () => {
  const { app, updateApp } = useApp();
  const { wallet } = app.state;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const airdrop: IAirdrop = app.airdrops.active[0];
  const [buyTicketsCount, setBuyTicketsCount] = useState(0);
  const ticketsForWallet = Number(!!wallet);
  const ticketsForNFTs =
    app.nfts.length + // any nft
    app.nfts.filter((x) => x.nftCollection == 'sofa' || x.nftCollection == 'souvenir').length + // +1 for sofa & souvenir
    app.nfts.filter((x) => x.nftCollection == 'souvenir' && (x.imageUrl || '').indexOf('frog') > -1).length; // +1 for the rarest souvenir
  const ticketsFromCoins = airdrop.ticketsCount;

  const calcTicketPrice = (cnt: number) => {
    return cnt
      ? app.config.airdrops.basePrice * Math.pow(app.config.airdrops.factor, ticketsFromCoins + cnt - 1) -
          airdrop.totalAmount
      : 0;
  };

  const buyTicket = async () => {
    setLoading(true);
    await postAirdropTickets(airdrop.id, buyTicketsCount);
    await updateApp();
    setLoading(false);
  };

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer vcenter>
      <Container style={{ gap: '30px' }}>
        <h1>{t('airdropTickets.heading.title')}</h1>
        <h2>🔥 {t('airdropTickets.heading.sub_title')} 🔥</h2>

        <LargeNote>
          <YourWallet />
        </LargeNote>

        <StatsContainer style={{ background: 'transparent', border: 'none' }}>
          <Row spread={true}>
            <Label>{t('airdropTickets.stats.title')}</Label>
            <Value></Value>
          </Row>
          <Row spread={true}>
            <SubLabel>{t('airdropTickets.stats.ticketsForWallet')}</SubLabel>
            <Value>{ticketsForWallet}</Value>
          </Row>
          <Row spread={true}>
            <SubLabel>{t('airdropTickets.stats.ticketsForNFTs')}</SubLabel>
            <Value>{ticketsForNFTs}</Value>
          </Row>
          <Row spread={true}>
            <SubLabel>{t('airdropTickets.stats.ticketsFromCoins')}</SubLabel>
            <Value>{ticketsFromCoins}</Value>
          </Row>
          <Row spread={true}>
            <Label>{t('airdropTickets.stats.ticketsTotal')}</Label>
            <Value>{ticketsForWallet + ticketsForNFTs + ticketsFromCoins}</Value>
          </Row>
        </StatsContainer>

        {/* {wallet && ( */}
        {/*   <CntButtonsContainer> */}
        {/*     <Row> */}
        {/*       <CntButton onClick={() => (buyTicketsCount ? setBuyTicketsCount(buyTicketsCount - 1) : undefined)}> */}
        {/*         <IconActionMinus /> */}
        {/*       </CntButton> */}
        {/*       <h2>{buyTicketsCount}</h2> */}
        {/*       <CntButton onClick={() => setBuyTicketsCount(buyTicketsCount + 1)}> */}
        {/*         <IconActionPlus /> */}
        {/*       </CntButton> */}
        {/*     </Row> */}
        {/*   </CntButtonsContainer> */}
        {/* )} */}

        {/* <BuyTicketButton */}
        {/*  onClick={buyTicket} */}
        {/*  disabled={loading || !wallet || !airdrop || app.state.balance < calcTicketPrice(buyTicketsCount)}> */}
        {/*  <Double>{t('airdropTickets.button.getNow')}</Double> */}
        {/*  {t('airdropTickets.button.tickets', { */}
        {/*    count: buyTicketsCount, */}
        {/*    price: compactAmount(calcTicketPrice(buyTicketsCount)), */}
        {/*  })}{' '} */}
        {/*  {t('airdropTickets.button.ticketWord_interval', { postProcess: 'interval', count: buyTicketsCount })} */}
        {/* </BuyTicketButton> */}

        <Row margin="36px 0 0">
          <LargeNote>
            <NavLink to="/airdrop">{t('airdropTickets.links.connectWallet')}</NavLink>
          </LargeNote>
        </Row>

        <Row margin="36px 0 0">
          <LargeNote>
            {t('airdropTickets.reminder')}
          </LargeNote>
        </Row>

        
      </Container>
    </PageContainer>
  );
};
