import styled from 'styled-components';

export const Container = styled.span.attrs({
  className: 'boost-container',
})`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  width: 100%;

  span {
    &.heading {
      color: #faff00;
      font-size: 48px;
      font-family: var(--font), sans-serif;
    }
    &.headingSub {
      color: #fff;
      font-size: 36px;
      display: flex;
      gap: 20px;

      .level {
        color: #fff;
      }
      .reward {
        color: #ddd;
        font-style: italic;
      }
    }
  }
`;

const Text = ({ heading, level, reward }: { heading: string; level: string; reward: string }) => {
  return (
    <Container>
      <span className="heading">{heading}</span>
      <span className="headingSub">
        <span className="level">{level}</span>
        <span className="reward">{reward}</span>
      </span>
    </Container>
  );
};

export default Text;
