import styled from 'styled-components';

import PageContainer from '../components/Wrappers';
import MenuBoosts from '../components/Boosts';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import BuyVialButton from '../components/pages/Boost/BuyVialButton';
import { InvoiceStatuses } from '@twa-dev/types';
import { getUserState, postShopLink, usePurchases } from '../lib/api';
import IconSepuku from '../components/Icons/IconSepuku';
import Loading from '../components/Loading';
import WebApp from '@twa-dev/sdk';
import { IProduct, IPurchase, IUserState } from '../lib/types';
import { doHapticFeedback } from '../lib/utils';
import { useEffectOnce } from 'react-use';
import { useApp } from '../contexts/AppContext';

const Container = styled.div<{ lang?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  > p {
    text-align: center;
    font-size: 34px;
    width: ${({ lang }) => (lang === 'en' ? '800px' : '')};
    font-style: italic;
    color: #eee;
    line-height: 1.3;
  }
`;

const Header = styled.div`
  width: 710px;
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.2px;
  text-transform: uppercase;
`;

export const BuyButton = styled.button`
  min-width: 483px;
  height: 95px;
  border-radius: 50px;
  background: #faff00;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  padding: 0 50px;

  :disabled {
    opacity: 0.5;
    filter: grayscale(100);
  }

  svg {
    margin-left: 35px;
    height: 86px;
  }
`;

const hasBoosts = (state: IUserState) => {
  return state.fuelLevel > 1 || state.luckLevel > 1 || state.speedLevel > 1 || state.turboLevel > 1;
};

const Boosts = () => {
  const { updateApp, setEvent, event, app } = useApp();
  const { t, i18n } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { data: purchases, isLoading } = usePurchases(app.state.extraFuelDuration);

  const handleBuy = async (product: IProduct) => {
    if (isProcessing) return;
    doHapticFeedback();
    setIsProcessing(true);
    const invoiceUrl = await postShopLink(product.id);
    WebApp.openInvoice(invoiceUrl.link);
  };

  useEffectOnce(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;

    const handleInvoiceClosed = ({ status }: { status: InvoiceStatuses }) => {
      if (status === 'paid') {
        // Start polling every 5 seconds
        intervalId = setInterval(async () => {
          const userState = await getUserState();
          // If it no longer has boosts, stop polling
          if (!hasBoosts(userState)) {
            await updateApp();
            setIsProcessing(false);
            setEvent({ type: 'purchased', product: 'reset', count: 1 });

            if (intervalId) clearInterval(intervalId);
            intervalId = null;
          }
        }, 5000);
      }
    };

    WebApp.onEvent('invoiceClosed', handleInvoiceClosed);

    return () => {
      WebApp.offEvent('invoiceClosed', handleInvoiceClosed);
      if (intervalId) clearInterval(intervalId);
    };
  });

  const product = app.config.shop.products.find((product) => product.type === 'boosts-resetter') as IProduct;
  const boughtCount = purchases.filter((purchase: IPurchase) => purchase.productId === product.id).length;
  const disabled = boughtCount >= product.maxCount || !hasBoosts(app.state);

  if (isLoading)
    return (
      <PageContainer vcenter>
        <Loading fontSize="100px" />
      </PageContainer>
    );

  return (
    <PageContainer vcenter>
      <Container lang={i18n.language}>
        <BuyVialButton />
        <Header>{t('boosts.boosts')}</Header>
        <MenuBoosts />
        <p>
          {t('boosts.byUpgradingYourBoosts.part1')}
          <strong>{t('boosts.byUpgradingYourBoosts.highlight')}</strong>
          <br />
          {t('boosts.byUpgradingYourBoosts.part2')}
        </p>
        <BuyButton onClick={() => handleBuy(product)} disabled={isLoading || isProcessing || disabled}>
          {!isProcessing && (
            <>
              {t('boosts.resetBoosts')}
              <IconSepuku fill="black" />
            </>
          )}
          {isProcessing && <Loading />}
        </BuyButton>
      </Container>
    </PageContainer>
  );
};

export default Boosts;
