import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ToggleLabel from '../../ToggleLabel';
import Toggle from 'react-toggle';


const ToggleContainer = styled.div.attrs({
  'data-tip': 'cave-toggle-container',
})`
  display: flex;
  width: fit-content;
  margin: 0 auto;

  height: 90px;
  opacity: 25%;
  scale: 0.6;

  position: fixed;
  top: 340px;
  left: 0;
  right: 0;
`;

const CaveToggle = ({
  defaultChecked,
  handleToggleChange,
}: {
  defaultChecked: boolean;
  handleToggleChange: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ToggleContainer>
      <ToggleLabel>
        {t('cave.time')}
        <Toggle icons={false} defaultChecked={defaultChecked} onChange={handleToggleChange} />
        {t('cave.rarity')}
      </ToggleLabel>
    </ToggleContainer>
  );
};

export default CaveToggle;
