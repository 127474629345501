import { useTranslation } from 'react-i18next';

import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { IconSurpriseNotNew } from '../../Icons/IconSurpriseNotNew';
// import { ImgOverlay } from './ImgOverlay';
// import { IconBadgeUpd } from '../../Icons/IconBadgeUpd';

const Airdrop = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <IconSurpriseNotNew />
        {/* <ImgOverlay style={{marginLeft: "-80px", top: "10px"}}> */}
          {/* <IconBadgeUpd /> */}
        {/* </ImgOverlay> */}
      </IconContainer>
      <ButtonLabel active={active}>{t('menu.airdrop')}</ButtonLabel>
    </ButtonContainer>
  );
};

export default Airdrop;
