import styled from 'styled-components';
import Row from '../components/Row';
import { useApp } from '../contexts/AppContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconSettings from '../components/Icons/IconSettings';
import { NavLink } from 'react-router-dom';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../lib/utils';
import Diary from '../components/Menu/Buttons/Diary';

const Header = styled.div.attrs({
  'data-tip': 'stats-header',
})`
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.8px;
  text-transform: uppercase;
`;

export const StatsContainer = styled.div.attrs({
  'data-tip': 'stats-container',
})`
  position: relative;
  text-align: center;

  width: 590px;
  padding: 80px 80px 20px 80px;
  background: #232323;
  border-radius: 45px;
  border: 2px solid #a7a7a3;

  section {
    margin-bottom: 40px;
  }

  img {
    height: 120px;
  }
`;

export const Label = styled.div.attrs({
  'data-tip': 'stats-label',
})`
  color: white;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 44.56px;
  text-align: left;
  text-transform: uppercase;
`;

export const SubLabel = styled.div.attrs({
  'data-tip': 'stats-sub-label',
})`
  color: #888888;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 41.02px;
  text-align: left;
  text-transform: uppercase;
`;

export const Value = styled.div.attrs({
  'data-tip': 'stats-value',
})`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 44.56px;
  text-align: left;
`;

const Statistics = () => {
  const { app } = useApp();
  const { t } = useTranslation();
  const { state } = app;

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer vcenter>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Row margin="30px 0 40px" gap="40px">
          <Header>
            {t('stats.statistics')} & {t('settings.settings')}
          </Header>
          <NavLink to="/language">
            <IconSettings />
          </NavLink>
        </Row>
      </motion.div>
      <StatsContainer>
        <section>
          <Row spread={true}>
            <Label>{t('stats.claimsDone')}</Label>
            <Value>{state.claimsCount}</Value>
          </Row>
        </section>
        <section>
          <Row spread={true}>
            <Label>{t('stats.tasksDone')}</Label>
            <Value>{state.tasksCount}</Value>
          </Row>
        </section>
        <section>
          <Row spread={true}>
            <Label>{t('stats.friendsCount')}</Label>
            <Value></Value>
          </Row>
          <Row spread={true}>
            <SubLabel>L1</SubLabel>
            <Value>{state.friendsLevel1Count}</Value>
          </Row>
          <Row spread={true}>
            <SubLabel>L2</SubLabel>
            <Value>{state.friendsLevel2Count}</Value>
          </Row>
          <Row spread={true}>
            <Label>{t('stats.total')}</Label>
            <Value>{state.friendsLevel1Count + state.friendsLevel2Count}</Value>
          </Row>
        </section>
        <section>
          <Row spread={true}>
            <Label>{t('stats.friendsReward')}</Label>
            <Value></Value>
          </Row>
          <Row spread={true}>
            <SubLabel>L1</SubLabel>
            <Value>{state.friendsLevel1Reward}</Value>
          </Row>
          <Row spread={true}>
            <SubLabel>L2</SubLabel>
            <Value>{state.friendsLevel2Reward}</Value>
          </Row>
          <Row spread={true}>
            <Label>{t('stats.total')}</Label>
            <Value>{state.friendsLevel1Reward + state.friendsLevel2Reward}</Value>
          </Row>
        </section>
        <section>
        <Row spread={true}>
          <Label>{t('stats.friendsL1')}</Label>
          <Value></Value>
        </Row>
        <Row spread={true}>
          <SubLabel>{t('stats.whoCompletedTasks')}</SubLabel>
          <Value>{state.taskCompletedFriendsL1Count}</Value>
        </Row>
        <Row spread={true}>
          <SubLabel>{t('stats.whoConnectedWallet')}</SubLabel>
          <Value>{state.walletFriendsL1Count}</Value>
        </Row>
        <Row spread={true}>
          <SubLabel>{t('stats.whoBookedNFTs')}</SubLabel>
          <Value>{state.nftsBookedFriendsL1Count}</Value>
        </Row>
        </section>

        <Row spread={true}>
          <Label>{t('stats.lottery')}</Label>
          <Value></Value>
        </Row>
        <Row spread={true}>
          <SubLabel>{t('stats.friendsLevel1LotteryReward')}</SubLabel>
          <Value>{state.friendsLevel1LotteryReward / 1000000} TON</Value>
        </Row>

        <NavLink to="/diary">
          <Diary />
        </NavLink>
      </StatsContainer>
    </PageContainer>
  );
};

export default Statistics;
