import { IconReserveClosed } from '../../Icons/IconReserveClosed';
import ButtonContainer from './ButtonContainer';
import IconContainer from './IconContainer';
import { ImgOverlay } from './ImgOverlay';

export const Icon = () => (
  <img alt="book-nft-movieposter" src="/img/nft/nfts-movieposter-thumb.webp" style={{ width: '120px' }} />
);

export const MenuBookMoviePoster = ({ active = false }) => {
  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <>
          <Icon />
          <ImgOverlay>
            <IconReserveClosed />
          </ImgOverlay>
        </>
      </IconContainer>
    </ButtonContainer>
  );
};
