import { deleteBackApi, getBackApi, patchBackApi, postBackApi, putBackApi } from './call';
import { IApp, IClaimReward, IUserState, Leader } from '../types';
import { UserUpgradeType } from '../../contexts/AppContext';
import { env } from '../env';
import startMock from '../../test/start.mock.json';
import leaderboardMock from '../../test/leaderboard.mock.json';
import tasksMock from '../../test/tasks.mock.json';
import { Lotteries } from '../types/lottery';

const { useMocks } = env;

export const getLeaderboard = async (balance_min: number, balance_max: number): Promise<{ leaderboard: Leader[] }> => {
  if (useMocks) return Promise.resolve(leaderboardMock);
  return getBackApi(`leaderboard?balance_min=${balance_min}&balance_max=${balance_max}`).then(
    (response) => response.data
  );
};

export const postStart = async (invited_by: number | undefined): Promise<IApp> => {
  if (useMocks) return Promise.resolve(startMock);
  return postBackApi(`start?invited_by=${invited_by || ''}`, invited_by ? { invited_by } : undefined).then(
    (response) => response.data
  );
};

export const deleteUser = async (): Promise<IApp> => deleteBackApi('user');

export const patchUserTasks = async (task_id: string) => {
  return patchBackApi('user/tasks', { task_id }).then((response) => response.data);
};

export const patchUserProfile = async (showPromo: boolean) => {
  return patchBackApi('user', { showPromo }).then((response) => response.data);
};

export const postFlocksMembership = async (flock_id: string, referer: string) => {
  return postBackApi('flocks/membership', { flock_id, referer }).then((response) => response.data);
};

export const postFriendsReward = async (): Promise<any> => {
  return postBackApi('user/friends/reward/buffer').then((response) => response.data);
};

export const postShopLink = async (product_id: string) => {
  return postBackApi('shop/link', { product_id }).then((response) => response.data);
};

export const getUserTasks = async () => {
  if (useMocks) return Promise.resolve(tasksMock);
  return getBackApi('user/tasks').then((response) => response.data);
};

export const getPurchases = async () => {
  return getBackApi('user/purchases').then((response) => response.data?.purchases);
};

export const getLotteries = async (): Promise<Lotteries> => {
  return getBackApi('lotteries').then((response) => response.data);
};

export const getLotteryWinners = async (lotteryId: string) => {
  return getBackApi(`lotteries/winners?uuid=${lotteryId}`).then((response) => response.data.winners);
};

export const getUserWonLotteries = async (): Promise<Lotteries> => {
  return getBackApi('user/lotteries/won').then((response) => response.data);
};

export const getUserState = async (): Promise<IUserState> => {
  return getBackApi(`user/state`).then((response) => response.data.state);
};

export const putFlocks = async (tg_chat_id: string) => {
  return putBackApi('flocks', { link: `https://t.me/${tg_chat_id}`, tg_chat_id }).then((response) => response.data);
};

export const postTickets = async (uuid: string, wallet: string) => {
  return postBackApi('lotteries/tickets', { uuid, wallet }).then((response) => response.data);
};

export const postAirdropTickets = async (airdropId: number, count: number) => {
  return postBackApi('airdrops/tickets', { airdropId, count }).then((response) => response.data);
};

export const postFlocks = async (tg_chat_id: string) => {
  return postBackApi('flocks', { link: `https://t.me/${tg_chat_id}`, tg_chat_id }).then((response) => response.data);
};

export const postUserClaim = async (): Promise<IClaimReward> => {
  return postBackApi('user/claim').then((response) => response.data);
};

export const postUserTurbo = async (): Promise<any> => {
  return postBackApi('user/state/turbo/activation').then((response) => response.data);
};

export const postUserUpgrade = async (type: UserUpgradeType): Promise<any> => {
  return postBackApi(`user/state/${type}`).then((response) => response.data);
};
