import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 850px;
  height: 95px;

  background: #d3baff;
  box-shadow: 0 0 20px 0 #d3baff;
  border-radius: 47.5px;
  gap: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  color: black;
  font-family: var(--font), sans-serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-transform: uppercase;
`;

const IconVial = styled((props) => (
  <svg width={41} height={59} viewBox="0 0 41 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1732_2)">
      <path
        d="M28.535 19.8075V18.7892H29.1588C30.1561 18.7892 30.9657 17.9831 30.9657 16.99V8.6894C30.9657 7.62821 30.1013 6.76758 29.0356 6.76758H10.9353C9.84018 6.76758 8.95237 7.65158 8.95237 8.74197V16.9374C8.95237 17.9597 9.78542 18.7892 10.8121 18.7892H11.4359V19.9497C11.4359 20.5377 11.1034 21.0712 10.5833 21.3516C4.14375 24.798 0 31.537 0 38.9848C0 51.6976 11.9658 61.6805 25.2712 58.3549C31.9141 56.694 37.2546 51.6918 39.259 45.1708C42.294 35.295 37.7357 25.5593 29.4188 21.2425C28.8791 20.9622 28.535 20.4131 28.535 19.8075ZM15.122 55.1791C9.62116 53.6194 5.3151 49.2773 3.79566 43.7884C1.3278 34.8783 6.00149 26.6322 13.3894 23.4603C14.0993 23.1546 14.5647 22.4673 14.5647 21.6982V15.6738H14.0661C12.971 15.6738 12.0832 14.7898 12.0832 13.6994V11.8574C12.0832 10.767 12.971 9.88299 14.0661 9.88299H25.9067C26.9725 9.88299 27.8368 10.7436 27.8368 11.8048V13.7519C27.8368 14.8131 26.9725 15.6738 25.9067 15.6738H25.4081V21.5989C25.4081 22.3797 25.8872 23.0728 26.6107 23.3727C32.755 25.9137 37.0767 31.9459 37.0767 38.9848C37.0767 49.9296 26.6283 58.4425 15.122 55.1791Z"
        fill="#303030"
      />
      <path
        d="M23.6247 0H16.7647C15.6437 0 14.7349 0.904887 14.7349 2.02112V6.57935C14.7349 7.69559 15.6437 8.60047 16.7647 8.60047H23.6247C24.7457 8.60047 25.6545 7.69559 25.6545 6.57935V2.02112C25.6545 0.904887 24.7457 0 23.6247 0Z"
        fill="#303030"
      />
      <path
        d="M26.8728 30.278L28.5428 32.7742C28.6953 33.004 28.9808 33.1072 29.2468 33.0332L32.1468 32.2232C32.7413 32.0577 33.2028 32.745 32.8195 33.2279L30.952 35.58C30.7799 35.7962 30.7682 36.098 30.9207 36.3258L32.5907 38.822C32.9329 39.3341 32.4186 39.9825 31.8378 39.7703L29.0141 38.7286C28.754 38.6331 28.4626 38.7149 28.2925 38.9311L26.425 41.2832C26.0417 41.7661 25.2614 41.4779 25.2869 40.8646L25.4081 37.8679C25.4198 37.5934 25.2517 37.3422 24.9916 37.2468L22.1678 36.2051C21.589 35.9909 21.6222 35.1653 22.2167 34.9979L25.1167 34.1878C25.3827 34.1139 25.5704 33.8763 25.5821 33.6018L25.7034 30.6051C25.7288 29.9898 26.5286 29.7659 26.8708 30.278H26.8728Z"
        fill="#303030"
      />
      <path
        d="M13.3504 31.1245L13.0238 33.097C12.9945 33.2781 13.0844 33.4572 13.2487 33.5409L15.0321 34.458C15.3978 34.6469 15.3078 35.1902 14.9011 35.2525L12.9162 35.5523C12.7344 35.5796 12.5916 35.7217 12.5603 35.9028L12.2338 37.8752C12.1673 38.2802 11.6197 38.362 11.434 37.996L10.5344 36.2104C10.4523 36.0469 10.2724 35.9534 10.0905 35.9826L8.10566 36.2825C7.69891 36.3448 7.45056 35.8522 7.74389 35.564L9.17142 34.1601C9.30244 34.0316 9.33568 33.833 9.25159 33.6694L8.35205 31.8839C8.16824 31.5179 8.5613 31.1304 8.92698 31.3173L10.7104 32.2344C10.8747 32.3181 11.0741 32.2889 11.2052 32.1604L12.6327 30.7565C12.926 30.4684 13.4169 30.7215 13.3504 31.1265V31.1245Z"
        fill="#303030"
      />
      <path
        d="M19.8094 23.6611L20.6581 24.9871C20.7363 25.1079 20.883 25.1663 21.0238 25.1312L22.553 24.736C22.8659 24.6542 23.1005 25.0202 22.8952 25.2695L21.892 26.4825C21.8001 26.5935 21.7903 26.7512 21.8686 26.8739L22.7173 28.1999C22.8913 28.4725 22.6136 28.8074 22.3125 28.6886L20.8439 28.1123C20.7089 28.0597 20.5545 28.0987 20.4626 28.2096L19.4594 29.4227C19.254 29.6719 18.8473 29.5123 18.8668 29.191L18.9627 27.6216C18.9705 27.4775 18.8864 27.3451 18.7515 27.2925L17.2829 26.7162C16.9817 26.5974 17.0071 26.1652 17.322 26.0834L18.8512 25.6881C18.992 25.6511 19.0917 25.5304 19.1015 25.3863L19.1973 23.8169C19.2169 23.4956 19.6393 23.3866 19.8133 23.6572L19.8094 23.6611Z"
        fill="#303030"
      />
      <path
        d="M3.24615 44.4468C5.51456 51.8147 12.3374 56.9026 20.0323 56.9824C27.8583 57.0623 34.8787 51.9452 37.1862 44.4468C35.2913 44.6473 32.2993 44.8129 28.7207 44.3008C22.201 43.3681 19.8681 41.0685 15.1748 40.7823C12.4606 40.6168 8.40873 41.0919 3.24811 44.4468H3.24615Z"
        fill="#303030"
      />
    </g>
    <defs>
      <clipPath id="clip0_1732_2">
        <rect width={40.2035} height={59} fill="white" />
      </clipPath>
    </defs>
  </svg>
))``;

const BuyVialButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container onClick={() => navigate('/boost/vials')}>
      <StyledText>{t('boosts.vials.buyVial')}</StyledText>
      <IconVial />
    </Container>
  );
};

export default BuyVialButton;
